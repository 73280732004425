import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FirebaseManager } from "../../services/firebase/FirebaseManager";
import { useAlert } from "../../context/AlertContext";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

export default function ForgotPassword(): JSX.Element {
	const { triggerAlert } = useAlert();
	const [resetEmail, setResetEmail] = useState("");
	const navigate = useNavigate();
	const { t } = useTranslation("LoginPages");

	return (
		<main className={`loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			<form
				id="ForgotPassword"
				onSubmit={async (e) => {
					e.preventDefault();
					try {
						await FirebaseManager.resetPassword(resetEmail.toLocaleLowerCase().trim());
						triggerAlert(t("resetEmailSuccess"), "success");
					} catch (error) {
						Sentry.captureException(error);
						console.error("Error during password reset:", error);
						triggerAlert(t("resetEmailError"));
						// navigate("/login");
						window.location.href = "/login";
					}
				}}
			>
				<label>
					{t("emailLabel")}
					<input
						id="email"
						className="text-area"
						type="text"
						value={resetEmail}
						onChange={(e) => setResetEmail(e.target.value)}
						required
						autoComplete="email"
					/>
				</label>
				<button type="submit" value="Reset Password">
					{t("resetPasswordButton")}
				</button>
			</form>
			{/* <Link to="/login"> {t("backToLogin")}</Link> */}
			<a href="/login" rel="nofollow">{t("backToLogin")}</a>
		</main>
	);
}
